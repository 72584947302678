.grid-container {
  display: grid;
  width: 100%;
  overflow: auto;
  gap: 2px;
  background-color: white;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticky-col {
  position: sticky;
  left: 0;
  background-color: #fcfcfc;
  z-index: 2;
}

.sticky-row {
  position: sticky;
  top: 0;
  z-index: 1;
}

.header-cell {
  height: 35px;
  font-size: 11px;
  font-weight: 600;
}

.top-left {
  z-index: 3;
}

.staff-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  padding: 0 10px;
  font-size: 11px;
  font-weight: 600;
}
.highlight {
  background-color: rgb(215 232 255) !important;
}
